<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Tab cards -->
      <div class="col">
        <h4 class="fw-bold text-primary">Community Profile Picture</h4>
        <p class="alert alert-primary">
          Your Community Profile Picture will show anywhere you interact
          within the Swandoola Community.
        </p>
        <div class="row mb-5">
          <div
            class="col text-center"
            :class="showEditLogo ? 'd-none' : ''"
          >
            <div class="row mb-3">
              <div class="col">
                <img
                  :src="file"
                  :alt="user.name"
                  class="rounded-circle mb-3 mx-auto"
                  width="200"
                  height="200"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label
                  for="file"
                  class="custom-file-upload"
                  @click="showEditLogo = true"
                >
                  <i class="far fa-cloud-upload me-2"></i>Choose a file to
                  upload
                </label>
                <input
                  type="file"
                  id="file"
                  ref="file"
                  @change="handleFileUpload"
                />
              </div>
            </div>
            <div class="row">
              <div class="col col-auto mx-auto small">
                <p><b>Supported Files:</b> JPG, PNG, GIF (Max 10MB)</p>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            :class="!showEditLogo ? 'd-none' : ''"
          >
            <div class="row">
              <div class="col">
                <h5>Crop & Zoom</h5>
                <vue-croppie
                  ref="croppieRef"
                  :enableOrientation="true"
                  :boundary="{ width: 300, height: 300 }"
                  :viewport="{ width: 300, height: 300, type: 'circle' }"
                  @result="result"
                  @update="update"
                ></vue-croppie>
              </div>
            </div>

            <div class="row my-3 text-center">
              <div class="col">
                <button
                  class="btn btn-sm btn-light me-2"
                  v-tooltip.bottom="'Rotate Left'"
                  @click="rotate(-90)"
                >
                  <i class="far fa-undo"></i>
                </button>
                <button class="btn btn-primary" @click="crop()">
                  <i class="far fa-save me-2"></i>Save Image
                </button>
                <button
                  class="btn btn-sm btn-light me-2"
                  v-tooltip.bottom="'Rotate Right'"
                  @click="rotate(90)"
                >
                  <i class="far fa-redo"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mb-3">
          <div class="col">
            <label for="community-description">Community Description</label>
            <textarea
              v-model="form.community_description"
              class="form-control"
              id="community-description"
              rows="4"
              placeholder="Tell the community about yourself..."
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col text-end">
            <button class="btn btn-light" @click="updateSettings">
              <i class="far fa-check"></i>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "croppie/croppie.css";

export default {
  props: [],
  data() {
    return {
      file: "",
      form: {
        community_description: "",
      },
      cropped: null,
      profilePicUrl: "",
      currentProfilePicture: "",
      uploadedUrl: "",
      showEditLogo: false,
      firstUploadWorkaround: true,
    };
  },
  methods: {
    async bind() {
      await this.$refs.croppieRef.bind({
        url: this.uploadedUrl,
      });
      // this.crop();
    },
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        format: "png",
        circle: true,
        size: "original",
      };
      this.$refs.croppieRef.result(options, (output) => {
        // this.cropped = output;
        this.file = output;
        this.submitFile();
      });
    },
    // EVENT USAGE
    cropViaEvent() {
      this.$refs.croppieRef.result(options);
    },
    result(output) {
      this.cropped = output;
    },
    update(val) {
      // console.log(val);
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    //
    base64ToBlob(base64, mime) {
      mime = mime || "";
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },
    submitFile() {
      var base64ImageContent = this.file.replace(
        /^data:image\/(png|jpg);base64,/,
        ""
      );
      var blob = this.base64ToBlob(base64ImageContent, "image/png");
      var formData = new FormData();
      formData.append("file", blob);

      if (!this.firstUploadWorkaround) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/community/upload-profile-picture",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then(({ data }) => {
            this.file = data.url;
            this.showEditLogo = false;
            this.firstUploadWorkaround = true;
            this.$EventBus.$emit("alert", data);
          });
      } else {
        this.firstUploadWorkaround = false;
      }
    },

    /*
        Handles a change on the file upload
      */
    handleFileUpload() {
      // this.file = this.$refs.file.files[0];

      const files = this.$refs.file.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.uploadedUrl = e.target.result;
          this.bind();
          this.crop();
        };
        reader.readAsDataURL(files[0]);
      }

      this.$refs.croppieRef.bind({
        url: this.file.src,
      });
      // this.submitFile();
    },
    updateSettings() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/settings/community/update",
          this.form
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshUser");
        });
    },
  },
  mounted() {
    // Upon mounting of the component, we accessed the .bind({...})
    // function to put an initial image on the canvas.
    this.form.community_description = this.user.community_description;
    this.profilePicUrl = this.user.profile_pic_url;
    this.currentProfilePicture = this.profilePicUrl;
    this.file = this.profilePicUrl;
    this.$refs.croppieRef.bind({
      url: this.currentProfilePicture,
    });
  },
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    token() {
      return this.$store.getters['auth/token'];
    },
  },
  components: {},
};
</script>

<style>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
